<template>
  <div>
    <div class="vx-card p-8">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <h4>คืนเครดิต</h4>
          <br>
          <div class="mt-8">
            <vs-input class="w-full" v-validate="'required'" label-placeholder="ยูสเซอร์เนม"
              v-model="username" name="username" />
            <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
          </div>
          <div class="mt-8">
            <vs-input class="w-full" label-placeholder="จำนวนเงิน"
              v-model="refund[0].trans_amount" name="amount" disabled />
          </div>
          <div class="vx-row">
            <!-- FROM -->
            <div class="vx-col md:w-1/5 w-full mt-5">
                <vs-select placeholder="หมวดบัญชี" v-model="fromCategory" class="text-sm">
                <vs-select-item :key="index" :value="item.code" :text="item.name"
                  v-for="(item, index) in categories" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-5">
              <vs-select placeholder="บัญชีโอน" v-model="FromBankAcc" class="text-sm">
                <vs-select-item :key="index" :value="item" :text="`${item.bank_fullname}`"
                  v-for="(item, index) in fromAccountList" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/5 w-full">
              <vs-input label-placeholder="เลขบัญชี" v-model="FromBankAcc.bank_number"
                 disabled />
            </div>
            </div>
            <div class="vx-row mt-2">
            <!-- TO -->
            <div class="vx-col md:w-1/5 w-full mt-5">
              <vs-select placeholder="บัญชีผู้รับ" v-model="ToBankAcc" class="text-sm">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in bank" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/5 w-full">
              <vs-input v-validate="'required'" label-placeholder="ชื่อบัญชีผู้รับ" v-model="ToBankFullname"
                name="ToBankFullname" />
              <span class="text-danger text-sm"
                v-show="errors.has('ToBankFullname')">{{ errors.first('ToBankFullname') }}</span>
            </div>
            <div class="vx-col md:w-1/5 w-full">
              <vs-input v-validate="'required|decimal'" label-placeholder="เลขบัญชี" v-model="ToBankNumber"
                name="ToBankNumber" />
              <span class="text-danger text-sm"
                v-show="errors.has('ToBankNumber')">{{ errors.first('ToBankNumber') }}</span>
            </div>
            
          </div>
          <div class="mt-8">
            <vs-textarea class="w-full" label="หมายเหตุ" v-model="refund[0].trans_info"
               />
          </div>
          <vs-button 
           :disabled="isFuckingLoading || !(FromBankAcc && ToBankAcc && ToBankNumber && username)" color="success"  type="relief"  class="mt-8" @click="verify() ">ยืนยัน</vs-button>
          <!-- popup  -->
          <vs-popup title="กรุณาใส่รหัส 2-Factor เพื่อทำการยืนยัน" :active.sync="OTPPopup">
            <div class="p-2 px-6 ">
              <vs-input 
                v-validate="'required'"
                v-model.trim="otp" 
                type ="text"
                placeholder="กรุณากรอกรหัส 2-Factor"
                class="mx-auto"/>
              <div class="vx-row mt-4">
                <vs-button class="vx-col md:w-1/3 mx-auto" 
                :disabled="isFuckingLoading ||!(FromBankAcc && ToBankNumber && otp)" 
                @click="submitOTP()" type="filled" size="small"
                color="success">ยืนยันOTP
                </vs-button>
                <!-- <vs-button class="vx-col md:w-1/3 mx-auto" @click="closeOTPPopup" type="filled" size="small" color="dark"
                  icon-pack="feather" icon="icon-close">ปิด
                </vs-button> -->
              </div>
            </div>
          </vs-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Validator
} from 'vee-validate'
import axios from '../../axios'
const dict = {
  custom: {
    username: {
      required: 'กรุณากรอกข้อมูล'
    },
    ToBankNumber: {
      required: 'กรุณากรอกข้อมูล',
      decimal: 'ต้องเป็นตัวเลขเท่านั้น'
    },
    ToBankFullname: {
      required: 'กรุณากรอกข้อมูล'
    }
  }
}

Validator.localize('en', dict)

export default {
  data () {
    return {
      username: '',
      info: null,
      bank: [
        {
          text: 'ไทยพาณิชย์',
          value: '014'
        },
        {
          text: 'กรุงเทพ',
          value: '002'
        },
        {
          text: 'กสิกรไทย',
          value: '004'
        },
        {
          text: 'กรุงไทย',
          value: '006'
        },
        {
          text: 'ธกส.',
          value: '034'
        },
        {
          text: 'ทหารไทยธนชาต',
          value: '011'
        },
        {
          text: 'ไอซีบีซี',
          value: '070'
        },
        {
          text: 'ไทยเครดิต',
          value: '071'
        },
        {
          text: 'ซิตี้แบงก์',
          value: '017'
        },
        {
          text: 'ซูมิโตโม มิตซุย',
          value: '018'
        },
        {
          text: 'สแตนดาร์ดชาร์เต',
          value: '020'
        },
        {
          text: 'ซีไอเอ็มบีไทย',
          value: '022'
        },
        {
          text: 'ยูโอบี',
          value: '024'
        },
        {
          text: 'กรุงศรีอยุธยา',
          value: '025'
        },
        {
          text: 'ออมสิน',
          value: '030'
        },
        {
          text: 'เอชเอสบีซี',
          value: '031'
        },
        {
          text: 'มิซูโฮ',
          value: '039'
        },
        {
          text: 'ธอส.',
          value: '033'
        },
        {
          text: 'แลนด์ แอนด์เฮ้าส์',
          value: '073'
        },
        // {
        //   text: 'ธนชาต',
        //   value: '065'
        // },
        {
          text: 'ทิสโก้',
          value: '067'
        },
        {
          text: 'เกียรตินาคิน',
          value: '069'
        },
        {
          text: 'อิสลาม',
          value: '066'
        }
      ],
      categories: [
        {
          name: 'บัญชีฝาก SCBALL',
          code: 'bank'
        },
        {
          name: 'บัญชีฝาก SCBONLY',
          code: 'bank_scb'
        }
      ],
      bankscb: [],
      bankscbonly: [],
      refund: [],
      OTPPopup: false,
      FromBankAcc: [],

      ToBankAcc: null,
      ToBankNumber: '',
      ToBankFullname: '',
      otp: null,
      isFuckingLoading: false,
      fromCategory: null,
      bankData: {},
      verifyData: {},
      isReadyToConfirm: false
    }
  },
  computed: {
    fromAccountList () {
      return this.bankData[this.fromCategory]
    }
    // fromAccountListscbonly () {
    //   return this.bankscbonly[this.fromCategory]
    // }
  },
  async mounted () {
    // await axios
    //   .get('bank/bank/scb')
    //   .then(response => (this.bankscb = response.data))
    // await axios
    //   .get('bank/bank/scbonly')
    //   .then(response => (this.bankscbonly = response.data))
    await axios
      .get(`bank/refund/${this.$route.params.transNo}/${this.$route.params.bank}/${this.$route.params.id}`)
      .then(response => (this.refund = response.data))
    const fetch = await axios.get('/internal-transfer/list')
    if (fetch && fetch.data && fetch.data.success) {
      this.bankData = fetch.data.data
    } else {
      this.$vs.notify({
        time: 4000,
        color: 'danger',
        position: 'top-right',
        icon: 'error',
        title: 'เกิดข้อผิดพลาดในการดึงข้อมูล',
        text: this.status_wd.info
      })
    }
  },
  methods: {
    showOTPPopup () {
      this.OTPPopup = true
    },
    closeOTPPopup () {
      this.OTPPopup = false
    },
    async verify () {
      this.isFuckingLoading = true
      const reqVerify = await axios.post('/internal-transfer/verify', {
        fromCategory: this.fromCategory,
        fromAccount: {bank_id:this.FromBankAcc.bank_id, bank_code:this.FromBankAcc.bank_code, bank_fullname:this.FromBankAcc.bank_fullname, bank_number:this.FromBankAcc.bank_number},
        toCategory: this.ToBankAcc,
        toAccount: {bank_code:this.ToBankAcc, bank_fullname:this.ToBankFullname, bank_number:this.ToBankNumber},
        amount: '1'
      })

      if (reqVerify && reqVerify.data && reqVerify.data.success) {
        const { verifyData } = reqVerify.data.data
        this.verifyData = verifyData
        this.isReadyToConfirm = true
        this.OTPPopup = true
      } else {
        this.$vs.notify({
          time: 4000,
          color: 'danger',
          position: 'top-right',
          icon: 'danger',
          title: 'เกิดข้อผิดพลาดในการทำรายการ',
          text: reqVerify.data.message
        })
      }

      this.isFuckingLoading = false
    },
    async submitOTP () {
      this.isFuckingLoading = true

      const reqConfirm = await axios.post('/internal-transfer/confirm', {
        fromCategory: this.fromCategory,
        fromAccount: {bank_id:this.FromBankAcc.bank_id, bank_number:this.FromBankAcc.bank_number},
        toCategory: this.ToBankAcc,
        toAccount: {bank_number:this.ToBankNumber},
        amount: '1',
        otp: this.otp,
        verifyData: this.verifyData
      })

      if (reqConfirm && reqConfirm.data && reqConfirm.data.success) {
        this.$vs.notify({
          time: 4000,
          color: 'success',
          position: 'top-right',
          icon: 'success',
          title: 'โอนเงินสำเร็จ',
          text: reqConfirm.data.message
        })
        this.clearData()
      } else {
        this.$vs.notify({
          time: 4000,
          color: 'danger',
          position: 'top-right',
          icon: 'danger',
          title: 'โอนเงินไม่สำเร็จ',
          text: reqConfirm.data.message
        })
      }

      this.isFuckingLoading = false
    },
    clearData () {
      this.amount = null
      this.otp = null
      this.isFuckingLoading = false
      this.isReadyToConfirm = false
      this.OTPPopup = false
    }
  }
}

</script>

<style>

</style>
